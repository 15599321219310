import React from "react";
import SbEditable from "storyblok-react";
import { useSettings } from "../../../core/hooks/content.js";
import { useEditor } from "../../../core/hooks/editor.js";
import { getContrast } from "../../../helpers/theme/color.js";

const BlockDownload = ({ blok }) => {
   const inEditor = useEditor();

   const settings = useSettings();

   let style = {
      backgroundColor: settings.main_color.color || "black",
      color: settings.main_color?.color ? getContrast(settings.main_color.color) : "white",
   };

   return inEditor ? (
      <SbEditable content={blok}>
         <span className="block mt-8 mb-16 border border-gray-200 shadow-lg rounded-md">
            <span className="flex flex-col md:block md:clear p-8">
               <span className="order-2 w-full md:float-right md:w-auto md:px-8 roundedbg-blue-500 text-white font-bold py-2 px-4 rounded text-center" style={style}>
                  Afficher le dossier
               </span>
               <span className="order-1 md:block text-2xl font-bold mb-4 md:mb-0">{blok.title}</span>
            </span>
            {blok.description && <span className="block px-8 py-4 bg-gray-100 border-t border-gray-200 uppercase text-sm text-gray-500">{blok.description}</span>}
         </span>
      </SbEditable>
   ) : (
      <a href={blok.link} target="_blank" rel="noreferrer" className="block mt-8 mb-16 border border-gray-200 shadow-lg rounded-md">
         <span className="flex flex-col md:block md:clear p-8">
            <span className="order-2 w-full md:float-right md:w-auto md:px-8 roundedbg-blue-500 text-white font-bold py-2 px-4 rounded text-center" style={style}>
               Afficher le dossier
            </span>
            <span className="order-1 md:block text-2xl font-bold mb-4 md:mb-0">{blok.title}</span>
         </span>

         {blok.description && <span className="block px-8 py-4 bg-gray-100 border-t border-gray-200 uppercase text-sm text-gray-500">{blok.description}</span>}
      </a>
   );
};

export default BlockDownload;
