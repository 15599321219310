import { graphql, useStaticQuery } from "gatsby";

export const hasContent = (content) => {
   return content && content[0].content;
};

export const useSettings = () => {
   const { getSettings } = useStaticQuery(graphql`
      {
         getSettings: storyblokEntry(slug: { eq: "site-settings" }) {
            content
         }
      }
   `);

   return getSettings.content ? JSON.parse(getSettings.content) : false;
};
