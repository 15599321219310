import React from "react";
import Components from "../components/index";

export const RenderComponent = ({ blok }) => {
   return React.createElement(Components(blok.component || blok.type), { key: blok._uid, blok: blok });
};

export const RenderComponents = ({ bloks }) => {
   if (bloks)
      return bloks.map((blok, i) => {
         return <RenderComponent blok={blok} key={blok._uuid || i} />;
      });
   else return false;
};
