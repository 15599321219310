import React from "react";
import SbEditable from "storyblok-react";
// import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import FluidImage from "./fluidImage";
import Lightbox, { LightboxItem } from "./lightbox";

const BlockImage = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         {blok.image_lightbox ? (
            <Lightbox>
               <figure>
                  <LightboxItem image={blok.image.filename}>
                     <FluidImage blok={{ filename: blok.image.filename }} className="w-full rounded-lg" alt={blok.image.alt} />
                  </LightboxItem>
                  {blok.image.title && <figcaption className="text-center italic mt-2">{blok.image.title}</figcaption>}
               </figure>
            </Lightbox>
         ) : (
            <figure>
               <FluidImage blok={{ filename: blok.image.filename }} className="w-full rounded-lg" alt={blok.image.alt} />
               {blok.image.title && <figcaption className="text-center italic mt-2">{blok.image.title}</figcaption>}
            </figure>
         )}
      </SbEditable>
   );
};

export default BlockImage;
