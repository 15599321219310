import { getFixedGatsbyImage } from "gatsby-storyblok-image";
import React from "react";
import { Helmet } from "react-helmet";
import Navi from "../components/navi.js";
import Header from "../components/organisms/header/header.js";
import { useSettings } from "../core/hooks/content.js";

const Layout = ({ children, context = false, location = false, seo = false, page = false }) => {
   if (!context || !location || !seo) {
      throw new Error("You ommit to add Context, SEO or Location to Layout.js");
   }

   const settings = useSettings();
   let favicons = false;

   console.log(settings.favicon);

   if (settings.favicon && settings.favicon.filename) {
      favicons = settings.favicon.filename.endsWith(".svg")
         ? {
              180: settings.favicon.filename,
              32: settings.favicon.filename,
              16: settings.favicon.filename,
           }
         : {
              180: getFixedGatsbyImage(settings.favicon.filename, {
                 width: 180,
              }).src,
              32: getFixedGatsbyImage(settings.favicon.filename, {
                 width: 32,
              }).src,
              16: getFixedGatsbyImage(settings.favicon.filename, {
                 width: 16,
              }).src,
           };
   }

   console.log(favicons);

   return (
      <div id="site" className="px-8">
         {page && favicons ? (
            <Helmet htmlAttributes={{ lang: "fr" }} title={`${location.pathname === "/" ? "" : `${page.name} | `} ${settings.title}`}>
               <link rel="apple-touch-icon" sizes="180x180" href={favicons[180]} />
               <link rel="icon" type="image/png" sizes="32x32" href={favicons[32]} />
               <link rel="icon" type="image/png" sizes="16x16" href={favicons[16]} />
               <link rel="shortcut icon" href={favicons[32]} />
            </Helmet>
         ) : (
            <Helmet htmlAttributes={{ lang: "fr" }} title={`${location.pathname === "/" ? "" : `${page.name} | `} ${settings.title}`} />
         )}

         {settings && settings.font_script && <div dangerouslySetInnerHTML={{ __html: settings.font_script }} />}

         <a href="#main" className="absolute w-0 h-0 overflow-hidden bg-clip-border focus:left-6 focus:top-6 z-1000 focus:bg-white focus:px-8 focus:py-4 focus:w-auto focus:h-auto">
            Passer au contenu principal
         </a>

         <Header />

         <div className="container flex flex-col items-start py-4 mx-auto sm:flex-row">
            <Navi navItems={context} location={location} />
            <main className="flex-1 w-full max-w-full overflow-hidden ds-main">{children}</main>
         </div>

         <footer className="my-8">
            <div className="container pt-8 mx-auto text-right border-t border-gray-200 border-solid">
               <a href="#site" className="text-gray-800 hover:underline focus:underline active:underline">
                  Retour en haut de page
               </a>
            </div>
         </footer>
      </div>
   );
};

export default Layout;
