import React from "react";
import SbEditable from "storyblok-react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

const BlockCode = ({ blok }) => {
   console.log(blok);
   return (
      <SbEditable content={blok}>
         {blok.content?.length > 0 && (
            <SyntaxHighlighter language={blok.attrs.class.split("-")?.[1]} style={dracula} showLineNumbers={true}>
               {blok?.content?.[0]?.text}
            </SyntaxHighlighter>
         )}
      </SbEditable>
   );
};

export default BlockCode;
