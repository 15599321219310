import React from "react";
import SbEditable from "storyblok-react";
import { RenderComponent } from "../../../core/hooks/components";

const Content = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <div>{blok.body && blok.body.content.map((childBlok, index) => <RenderComponent blok={childBlok} key={childBlok._uid} />)}</div>
      </SbEditable>
   );
};

export default Content;
