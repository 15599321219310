import Paragraph from "../../components/atoms/richtext/paragraph";
import UnorderedList from "../../components/atoms/richtext/unorderedList";
import OrderedList from "../../components/atoms/richtext/orderedList";
import Blockquote from "../../components/atoms/richtext/blockquote";
import HorizontalRule from "../../components/atoms/richtext/horizontalRule";
import ComponentNotFound from "../../components/component_not_found";
import Navi from "../../components/navi";
import BodyRender from "./bodyRender";
import Heading from "../../components/atoms/richtext/heading";
import Button from "../../components/atoms/buttons/button";
import Content from "../../components/organisms/content/content";
import BlockImage from "../../components/atoms/images/blockImage";
import Page from "../../components/page";
import BlockPreview from "../../components/organisms/block/blockPreview";
import BlockDownload from "../../components/organisms/block/blockDownload";
import BlockPreviewTypography from "../../components/organisms/block/blockPreviewTypography";
import BlockTypography from "../../components/organisms/block/blockTypography";
import BlockColors from "../../components/organisms/block/blockColors";
import BlockCode from "../../components/organisms/block/blockCode";

const ComponentList = {
   blok: BodyRender,
   paragraph: Paragraph,
   heading: Heading,
   bullet_list: UnorderedList,
   ordered_list: OrderedList,
   blockquote: Blockquote,
   horizontal_rule: HorizontalRule,
   page: Page,
   global_navi: Navi,
   button: Button,
   richtext: Content,
   image: BlockImage,
   block_wysiwyg: Content,
   block_image: BlockPreview,
   block_download: BlockDownload,
   block_typography_details: BlockPreviewTypography,
   block_typography: BlockTypography,
   block_colors: BlockColors,
   code_block: BlockCode,
};

const Components = (type) => {
   if (typeof ComponentList[type] === "undefined") {
      return ComponentNotFound;
   }
   return ComponentList[type];
};

export default Components;
