import { Link, useStaticQuery, graphql } from "gatsby";

import React from "react";
import FluidImage from "../../atoms/images/fluidImage";
// import Brand from "../../images/brand.png";

const Header = () => {
   const { sbEntry } = useStaticQuery(graphql`
      {
         sbEntry: storyblokEntry(slug: { eq: "site-settings" }) {
            content
         }
      }
   `);

   const siteSettings = JSON.parse(sbEntry.content);

   return (
      <header className="block ds-nav p-0 bg-white bg-fixed mt-8 w-full container mx-auto">
         <div className="w-full sm:w-4/12 md:w-3/12 pr-6">
            <h1 className="font-bold text-lg text-gray-800">
               <Link to="/">{siteSettings.logo && siteSettings.logo.filename ? <FluidImage blok={siteSettings.logo} className="max-w-xs" /> : <p>Site title</p>}</Link>
            </h1>
         </div>
      </header>
   );
};

export default Header;
