import React from "react";
import { Link } from "gatsby";

const getClassFromMark = (mark) => {
   switch (mark.type) {
      case "bold":
         return "font-bold";
      case "italic":
         return "italic";
      case "underline":
         return "underline";
      case "strike":
         return "line-through";
      case "styled":
         switch (mark.attrs.class) {
            case "text_center":
               return "text-center";
            case "text_right":
               return "text-right";
            default:
               return "text-left";
         }

      default:
         return false;
   }
};

const isValidUrl = (string) => {
   try {
      new URL(string);
   } catch (_) {
      return false;
   }

   return true;
};

const Wysiwyg = ({ text }) => {
   let textClass = [];
   // let paragraphClass = "";
   let isLink = false;
   let isBreak = false;

   if (text.type === "hard_break") {
      isBreak = true;
   } else if (text.marks) {
      text.marks.forEach((mark) => {
         if (mark.type === "link") {
            isLink = mark.attrs;

            delete isLink.uuid;
            delete isLink.linktype;
         }

         let markClass = getClassFromMark(mark);

         if (markClass) textClass.push(markClass);
      });
   }

   if (isBreak) {
      return <br />;
   } else if (isLink) {
      if (isValidUrl(isLink.href)) {
         return (
            <a href={isLink.href} className={`${textClass.join(" ")} underline hover:no-underline focus:no-underline`} target={isLink.target}>
               {text.text}
            </a>
         );
      } else {
         return isLink.target ? (
            <a href={isLink.href} className={`${textClass.join(" ")} underline hover:no-underline focus:no-underline`} target={isLink.target}>
               {text.text}
            </a>
         ) : (
            <Link to={isLink.href} className={`${textClass.join(" ")}underline hover:no-underline focus:no-underline`}>
               {text.text}
            </Link>
         );
      }
   } else {
      return textClass.length > 0 ? <span className={textClass.join(" ")}>{text.text}</span> : <>{text.text}</>;
   }
};

export default Wysiwyg;
